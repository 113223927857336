import { Link } from "gatsby"
import React from "react"

import Logo from "../images/smLogo.png"

const Header = ({ siteTitle }) => (
  <header className="bg-skblue-100 shadow-md w-full mb-20 pb-2">
    <div className="justify-between px-10 sm:flex">
      <Link className="font-medium hidden sm:block text-sm" to="/">
        <img src={Logo} alt={""} className="-mb-1 h-12 my-1" />
      </Link>

      <div className="">
        <Link className="text-sm font-medium md:block sm:hidden" to="/">
          <img
            src={Logo}
            alt={"Skyrocket Logo"}
            className="h-12 mb-0 mx-auto sm:hidden visible z-10"
          />
        </Link>
        <br />
        <div className="-mt-4 flex justify-center">
          <div className="py-2 px-4 font-normal h-10 hover:bg-skblue-200 text-white font-bold uppercase">
            <Link to="/about/">About</Link>
          </div>
          <div className="py-2 px-4 font-normal h-10 hover:bg-skblue-200 text-white font-bold uppercase">
            <Link to="/services/">Services</Link>
          </div>
          <div className="py-2 px-4 font-normal h-10 hover:bg-skblue-200 text-white font-bold uppercase">
            <Link to="/contact/">Contact</Link>
          </div>
        </div>
      </div>
    </div>
  </header>
)

// Header.propTypes = {
//   siteTitle: PropTypes.string,
// }

// Header.defaultProps = {
//   siteTitle: ``,
// }

export default Header
