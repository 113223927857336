/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import instagram from "../images/instagram.png"
import facebook from "../images/facebook.png"

import Header from "./header"
import "./layout.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      <div>
        <main className="min-h-screen">{children}</main>
        <footer className="bg-skblue-100 text-white py-5">
          <div className="grid grid-cols-1 lg:grid-cols-2 max-w-full px-10 text-center">
            <div className="lg:text-left">
              © {new Date().getFullYear()}, Skyrocket Digital Marketing
            </div>
            <div className="flex justify-center lg:justify-end">
              <a
                href="https://www.facebook.com/skyrocket.dm/"
                target="_blank"
                className="mx-4"
                rel="noopener noreferrer"
              >
                <img src={facebook} alt="Facebook" className="h-10 mb-0" />
              </a>
              <a
                href="https://instagram.com/skyrocket.dm"
                target="_blank"
                className="mx-4"
                rel="noopener noreferrer"
              >
                <img src={instagram} className="h-10 mb-0" alt="Instagram" />
              </a>
            </div>
          </div>
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
